import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SmallTermsAcceptedStatusIndicator from './SmallTermsAcceptedStatusIndicator';

const styles = {
  container: {
    p: 1,
    px: 2.5,
    pb: 5,
  },
  textField: {
    pb: 2.5,
  },
  metaInfo: {
    mb: 2,
  },
  divider: {
    my: 2.5,
    mx: 0.625,
  },
  passwordContainer: {
    py: 1,
    px: 5,
    textAlign: 'center',
  },
  successMessage: {
    my: 1,
    color: 'success.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
  },
};

function UserDetails(props) {
  const {
    editingProfile,
    name,
    setName,
    nameIsValid,
    email,
    setEmail,
    emailIsValid,
    userData,
    userAttributesWithEmail,
    openTermsDialog,
    passwordResetSent,
    passwordResetRequested,
    changePassword
  } = props;

  return (
    <Box sx={styles.container}>
      {editingProfile ? (
        <TextField
          error={!nameIsValid}
          fullWidth
          margin="normal"
          id="profile-name-update"
          label="Name"
          variant="standard"
          value={name}
          onChange={(event) => setName(event.target.value)}
          helperText={nameIsValid ? null : "Please provide a valid name"}
        />
      ) : (
        <Typography>
          <Box component="span" fontWeight="bold">{name || '-'}</Box>
        </Typography>
      )}

      {editingProfile ? (
        <TextField
          error={!emailIsValid}
          fullWidth
          margin="normal"
          id="profile-email-update"
          label="Email"
          variant="standard"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          sx={styles.textField}
          helperText={email !== userAttributesWithEmail.email ? (emailIsValid ? null : "Please provide a valid email address") : null}
        />
      ) : (
        <Typography sx={styles.metaInfo}>{email || '-'}</Typography>
      )}

      {editingProfile ? null : (
        <Stack direction="row" spacing={2} sx={styles.metaInfo}>
            <Typography>
            <Box component="span" fontWeight="bold">Onboarded:</Box>{' '}
            {userData ? new Date(userData.data.attributes.created_at).toLocaleDateString() : '-'}
            </Typography>
            <Typography>
            <Box component="span" fontWeight="bold">Last updated:</Box>{' '}
            {userData ? new Date(userData.data.attributes.updated_at).toLocaleDateString() : '-'}
            </Typography>
        </Stack>
      )}

      {(!editingProfile && userData) ? (
        <SmallTermsAcceptedStatusIndicator
          openTermsDialog={openTermsDialog}
          canOpenTermsDialog={true}
          latestTermsAcceptedAt={userData.data.attributes.latest_terms_accepted_at}
        />
      ) : (
        <Box sx={styles.metaInfo}>&nbsp;</Box>
      )}

      {editingProfile ? 
        <Box>&nbsp;</Box> :
        <Divider sx={styles.divider} />
      }

      {editingProfile ? null :(
      <Box sx={styles.passwordContainer}>
        {passwordResetSent ? (
          <Typography sx={styles.successMessage}>
            <CheckCircleOutlineIcon />
            A password reset link has been sent to your email.
          </Typography>
        ) : (
          <Button
            disabled={!userData || !userAttributesWithEmail || editingProfile || passwordResetRequested}
            fullWidth
            variant="contained"
            color="primary"
            onClick={changePassword}
          >
            Change Password
          </Button>
        )}
      </Box>
      )}
    </Box>
  );
}

export default UserDetails;