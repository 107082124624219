import React, { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import SmallTermsAcceptedStatusIndicator from './SmallTermsAcceptedStatusIndicator';

function APIKeySecretDialog(props) {
    const {
        isOpen,
        clientID,
        APIKeySecret,
        closeAPIKeySecretDialog
    } = props;

    return (
        <Dialog
        open={isOpen}
        onClose={() => {}}
        maxWidth="sm"
        >
            <DialogTitle sx={{backgroundColor: "#eee"}}>New API Key</DialogTitle>
            <DialogContent sx={{padding: "20px 40px"}}>
                <p>The following Client ID and API Key can be used when uploading sequencing data using the Keynome Manager CLI.</p>
                <p><b>Client ID:</b> <pre style={{textAlign: "center", backgroundColor: "#eee", padding: "15px 10px", borderRadius: "5px"}}>{clientID}</pre></p>
                <p><b>API Key: <pre style={{textAlign: "center", backgroundColor: "#eee", padding: "15px 10px", borderRadius: "5px"}}>{APIKeySecret}</pre></b></p>
                <p><b>Please copy this API Key value to a secure location, we will not be able to display it to you again.</b></p>
            </DialogContent>
            <DialogActions sx={{backgroundColor: "#eee"}}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "20px" }}
                    onClick={closeAPIKeySecretDialog}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default APIKeySecretDialog;