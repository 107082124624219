import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useInView } from 'react-intersection-observer';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { ListItem, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function APIKeyListItem(props) {
  const {
    apiKey,
    deleteAPIKey
  } = props;

  const [deletingKey, setDeletingKey] = useState(false)

  return (
    <Box>
      <Grid container>
        <Grid item xs={4} sx={{padding: "13px 20px"}}>
          <Typography sx={{textAlign: "left"}}><b>{apiKey.attributes.name}</b></Typography>
        </Grid>
        <Grid item xs={6} sx={{padding: "13px 20px"}}>
          <Typography>Client ID: <pre style={{display: "inline", backgroundColor: "#eee", padding: "5px 10px", borderRadius: "5px"}}>{apiKey.attributes.auth0_client_id}</pre></Typography>
        </Grid>
        <Grid item xs={2} sx={{textAlign: "right", padding: "5px 20px"}}>
        <IconButton
          disabled={deletingKey}
            sx={{color: "red"}} 
            aria-label="delete" 
            onClick={() => {
              setDeletingKey(true);
              deleteAPIKey(apiKey.id)
            }}
        >
          <DeleteIcon />
        </IconButton>
        </Grid>
        </Grid>
    </Box>
  );
}

export default APIKeyListItem;
