import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const styles = {
  container: {
    textAlign: "right",
    padding: "10px"
  },
  saveButton: {
    marginRight: "5px"
  }
};

function ProfileEditButtonGroup(props) {
  const {
    editingProfile,
    userData,
    userAttributesWithEmail,
    canSaveProfile,
    savingProfile,
    nameIsValid,
    emailIsValid,
    setEditingProfile,
    saveProfileEdit,
    cancelProfileEdit
  } = props;

  if (!editingProfile) {
    return (
      <Grid item xs={6} sx={styles.container}>
        <Button
          disabled={!userData || !userAttributesWithEmail}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setEditingProfile(true)}
        >
          Edit
        </Button>
      </Grid>
    );
  }

  return (
    <Grid item xs={6} sx={styles.container}>
      <Button
        disabled={!canSaveProfile || savingProfile || !nameIsValid || !emailIsValid}
        variant="contained"
        size="small"
        color="success"
        onClick={saveProfileEdit}
        sx={styles.saveButton}
      >
        Save
      </Button>
      <Button
        disabled={savingProfile}
        variant="contained"
        size="small"
        color="error"
        onClick={cancelProfileEdit}
      >
        Cancel
      </Button>
    </Grid>
  );
}

export default ProfileEditButtonGroup;