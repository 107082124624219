import React, {useMemo,useState, useCallback, useEffect} from 'react';

import {useDropzone} from 'react-dropzone'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import AddSamplesFileListItem from './AddSamplesFileListItem';
import SamplesList from './SamplesList';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function AddSamplesDialog(props) {
    const { open, setOpen, activeRun, reloadSamples, uploadFile, backgroundUploadingFilesCt, samples, activeProject, setActiveRun, setSamples, setReloadRuns } = props;

    const [ stagedFiles, setStagedFiles] = useState({});
    const [ uploadingCounter, setUploadingCounter ] = useState(0);

    useEffect(() => {
     setStagedFiles({});
    }, [open])


    const onDrop = useCallback(acceptedFiles => {
        let newStagedFiles = stagedFiles;
        const isIllumina = activeRun?.attributes.sequencing_platform === 'illumina' || activeRun?.attributes.platform === 'illumina'
        for (let file of acceptedFiles) {
            let sampleName = file.path.replace('.fastq.gz', '')
            if (isIllumina) {
                const isR1 = sampleName.endsWith('1') || sampleName.endsWith('R1')
                if (isR1) {
                    sampleName = sampleName.replace(/_(1|R1)$/, '')
                } else {
                    sampleName = sampleName.replace(/_(2|R2)$/, '')
                }

                if (!newStagedFiles[sampleName]) { 
                    newStagedFiles[sampleName] = {
                        'R1': null,
                        'R2': null
                    }
                }

                const fileKey = isR1 ? 'R1' : 'R2'
                newStagedFiles[sampleName][fileKey] = file
            } else {
                newStagedFiles[sampleName] = file
            }
        }

        setStagedFiles(newStagedFiles)
    }, [setStagedFiles, stagedFiles, activeRun])

    const isIllumina = activeRun?.attributes.sequencing_platform === 'illumina' || activeRun?.attributes.platform === 'illumina'
    const acceptedSuffixes = ['.fastq.gz']

    const validateR1R2Suffix = (file) => {
        if (
            file.name.endsWith('_1.fastq.gz')
            || file.name.endsWith('_2.fastq.gz')
            || file.name.endsWith('_R1.fastq.gz')
            || file.name.endsWith('_R2.fastq.gz')
        ) {
            return null
        }

        return {
            "message": "File names must contain _1, _2, _R1, or _R2 suffix"
        }
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop, 
        'accept': {'application/gzip': acceptedSuffixes, 'application/x-gzip': acceptedSuffixes},
        validator: isIllumina ? validateR1R2Suffix : null
    })

    const uploadingStarted = useCallback(() => {
        setUploadingCounter(uploadingCounter + 1)
    }, [uploadingCounter, setUploadingCounter])

    const uploadingFinished = useCallback(() => {
        setUploadingCounter(uploadingCounter - 1)
    }, [uploadingCounter, setUploadingCounter])

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        reloadSamples();
        setOpen(false);
    };

    const deleteSample = useCallback(sampleNameToDelete => {
        let newStagedFiles = {};
        for (let sampleName in stagedFiles) {
            if (sampleName === sampleNameToDelete) {
                continue;
            }

            newStagedFiles[sampleName] = stagedFiles[sampleName]
        }

        setStagedFiles(newStagedFiles)
    }, [setStagedFiles, stagedFiles])

    const style = useMemo(() => {
        const dropZoneStyle = {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '20px',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              cursor: "pointer",
              outline: 'none',
              transition: 'border .24s ease-in-out'
        };

        const focusedStyle = {
          borderColor: '#2196f3'
        };

        const acceptStyle = {
          borderColor: '#00e676'
        };

        const rejectStyle = {
          borderColor: '#ff1744'
        };

        return {
            ...dropZoneStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }
    }, [
        isFocused,
        isDragAccept,
        isDragReject

    ]);

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        >
            <DialogTitle>
                <Grid container>
                    <Grid xs={8}>
                        {activeRun?.attributes.name}
                    </Grid>
                    <Grid xs={4} sx={{textAlign: "right"}}>
        { isIllumina ? (
                        <>
                        <img src={illuminaLogo} alt="platform-logo" height="30px" style={{marginBottom: '-8px', marginLeft: '4px', marginRight: '10px'}} />
                        Illumina Paired End
            </>

        ) : (
            <>
                        <img src={ontLogo} alt="platform-logo" height="30px" style={{marginBottom: '-8px', marginLeft: '4px', marginRight: '10px'}} />
                        ONT Single End
            </>
        )}
                    </Grid>
                </Grid>
      <Grid item xs={12}>
        <Box>
            <Grid container>
              <Grid item xs={6}>
                    <p style={{color: '#797979', marginBottom: '20px', fontSize: 15}}>
                        <b>UPLOAD SEQUENCING FILES</b>
                    </p>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right"}}>
            <p style={{color: '#797979', marginBottom: '20px', fontSize: 15}}>
                Keynome Manager Upload ID: <pre style={{display: "inline", backgroundColor: "#eee", padding: "5px 10px", borderRadius: "5px"}} >{activeRun?.attributes.uuid}</pre>
            </p>
            </Grid>
            </Grid>
            </Box>
            </Grid>
                <div {...getRootProps()} style={style}>
                  <input {...getInputProps()} />
                  <p style={{fontSize: 15}}>
                    <b>Click to open browser or drag/drop sequencing files</b>
                    <br/>
                    Files must be Gzip compressed with {isIllumina ? '_1/R1.fastq.gz or _2/R2.fastq.gz' : '.fastq.gz'} suffix
                    {isIllumina ? 
                        (
                            <>
                            <br/>
                            Paired sequencing files must have the same file name prior to _1/R1 or _2/R2 suffix
                            </>
                        ):
                        null
                    }
                </p>
                </div>
    {Object.keys(stagedFiles).length ? (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px', color: 'black', marginTop: '20px', fontSize: 15 }}>
        <Grid container sx={{ pl: 1, pr: 1, textAlign: 'center' }}>
          <Grid item xs={12} sm={isIllumina ? 3 : 2} sx={{ textAlign: 'left' }}>
            <p><b>Sample Name</b></p>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{isIllumina ? 'R1 ' : ''}File Name</p>
          </Grid>
        {
            isIllumina ? 
            (
              <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
                <p>R2 File Name</p>
              </Grid>
            ) : null

        }
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <p>{isIllumina ? 'Total ' : ''}File Size</p>
          </Grid>
          {isIllumina ? null : (
          <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
            <p>File Last Modified</p>
          </Grid>
          )}
          <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
            &nbsp;
          </Grid>
        </Grid>
      </Box>
      <List sx={{
          width: '100%', bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0'
      }}
      >
        {Object.keys(stagedFiles).map(sampleName => {
            if (!isIllumina) {
                const file = stagedFiles[sampleName]
                return <AddSamplesFileListItem sampleName={sampleName} file={file} r2File={null} deleteSample={deleteSample} activeRun={activeRun} uploadingStarted={uploadingStarted} uploadingFinished={uploadingFinished} uploadFile={uploadFile} reloadSamples={reloadSamples} />
            }    

            const r1File = stagedFiles[sampleName].R1
            const r2File = stagedFiles[sampleName].R2
            return <AddSamplesFileListItem sampleName={sampleName} file={r1File} r2File={r2File} deleteSample={deleteSample} activeRun={activeRun} uploadingStarted={uploadingStarted} uploadingFinished={uploadingFinished} uploadFile={uploadFile} isIllumina={true} reloadSamples={reloadSamples} />
        })}
      </List>
                </>
    ) : null}
        <Divider sx={{margin: '40px'}} />
            </DialogTitle>
            <DialogContent>
      <Grid item xs={12}>
        <p style={{color: '#797979', marginBottom: '20px'}}>
            <b>SAMPLES IN DATASET</b>
        </p>
        </Grid>
          <SamplesList samples={samples} activeProject={activeProject} selectedRun={activeRun} />
            </DialogContent>
            <DialogActions sx={{marginBottom: "15px"}}>
                {
                    backgroundUploadingFilesCt === 0 ?
                        null :
                        (
                            <p style={{color: "gray", marginRight: "20px", textAlign: "right"}}><small>Files may take a few minutes to upload<br />Feel free to close and upload{backgroundUploadingFilesCt === 1 ? '' : 's'} will continue in the background</small></p>
                        )
                }
                <Button variant="contained" color="primary" sx={{marginRight: "20px"}} onClick={() => {
                    handleClose()
                    setReloadRuns(true)
                    setActiveRun(null)
                    setSamples(null)
                }} disabled={uploadingCounter !== 0}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}

export default AddSamplesDialog;
